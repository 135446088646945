<template>
  <div class="MERCH logistics">
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 审核状态 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">名称/编码:</div> -->
            <el-input
              placeholder="请输入名称/编码"
              v-model="UsersApi.keyWords"
            ></el-input>
          </div>
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">状态:</div> -->
            <el-select
              clearable
              v-model="UsersApi.status"
              placeholder="选择状态"
            >
              <el-option
                v-for="item in userType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 搜索 -->
          <div class="sch-1 sch-2">
            <el-button type="primary" size="mini" @click="toSearch1">
              查 询
            </el-button>
          </div>
          <div class="sch-1 sch-2" style="margin-left: 18%">
            <el-button
              type="primary"
              size="mini"
              @click="toSearched"
              v-if="allJurisdiction.logistics164"
            >
              导入模板下载
            </el-button>
            <div class="bulesd fx">
              <el-upload
                :action="leadLogisticsUrl"
                :headers="{ TOKEN }"
                :show-file-list="false"
                :on-success="
                  (response, file) => {
                    upSuccess(response, file);
                  }
                "
              >
                <el-button
                  type="text"
                  style="color: #fff"
                  v-if="allJurisdiction.logistics164"
                  >导入物流公司</el-button
                >
              </el-upload>
              <span class="buled" v-if="allJurisdiction.logistics164">|</span
              ><el-button
                type="text"
                style="color: #fff"
                @click="openAdd"
                v-if="allJurisdiction.logistics75"
                >创建物流公司</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table :data="usersData.data" border style="width: 100%">
            <el-table-column label="序号" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.id }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="公司名称" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.name"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="公司编码 " align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.code"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.code }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="排序" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.sort }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="状态" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.status_ == 1 ? "正常" : "禁用" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!--  -->
                  <!-- <div @click="openAdd(row)">编辑</div> -->
                  <el-button
                    type="text"
                    @click="openAdd(row)"
                    v-if="allJurisdiction.logistics76"
                    >编辑</el-button
                  >
                  <!-- <el-popconfirm
                    @confirm="checkType(row)"
                    :title="`确定要禁用物流公司${row.name}？
                    禁用后商家发货时无法再选择当前物流公司。`"
                    v-if="row.status_ == 1"
                  >
                    <div slot="reference">
                      禁用
                    </div>
                  </el-popconfirm> -->
                  <el-button
                    type="text"
                    @click="checkType(row)"
                    v-if="row.status_ == 1 && allJurisdiction.logistics78"
                    >禁用</el-button
                  >
                  <!-- <el-popconfirm
                    @confirm="checkType(row)"
                    :title="`确定要启用物流公司${row.name}？
                    启用后商家发货时将允许选择当前物流公司。`"
                    v-if="row.status_ != 1"
                  >
                    <div slot="reference">
                      启用
                    </div>
                  </el-popconfirm> -->
                  <el-button
                    type="text"
                    @click="checkType(row)"
                    v-if="row.status_ != 1 && allJurisdiction.logistics78"
                    >启用</el-button
                  >
                  <!-- <el-popconfirm
                    @confirm="toDeltel(row)"
                    :title="`确定要删除物流公司${row.name}？删除后商家发货时无法再选择当前物流公司且无法恢复，请谨慎操作。`"
                  >
                    <div slot="reference">
                      删除
                    </div>
                  </el-popconfirm> -->
                  <el-button
                    type="text"
                    @click="toDeltel(row)"
                    v-if="allJurisdiction.logistics77"
                    >删除</el-button
                  >
                  <!-- <div @click="toDeltel(row)">删除</div> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="usersData.per_page"
          layout="total, prev, pager, next, jumper"
          :total="usersData.total"
          :current-page="UsersApi.page"
        >
        </el-pagination>
      </div>
      <!-- 新建编辑 -->
      <div>
        <el-dialog
          title="物流公司信息"
          :visible.sync="islogistics"
          @close="closeDialog"
          width="600px"
        >
          <div>
            <el-form ref="form" label-width="100px">
              <el-form-item label="物流公司编码">
                <el-input
                  @change="zz.test(AddData.code) ? '' : (AddData.code = '')"
                  :disabled="AddData.id != undefined ? true : false"
                  v-model="AddData.code"
                  placeholder="物流公司编码"
                ></el-input>
              </el-form-item>
              <el-form-item label="物流公司名称">
                <el-input
                  v-model="AddData.name"
                  placeholder="物流公司名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="排序">
                <el-input
                  v-model="AddData.sort"
                  placeholder="排序"
                  @change="zz.test(AddData.sort) ? '' : (AddData.sort = '')"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="islogistics = false">取 消</el-button>
            <el-button type="primary" @click="afiyAdd">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <!-- 导入发货弹窗 -->
    <el-dialog
      title="导入物流公司提醒"
      :visible.sync="dialogShiped"
      width="25%"
    >
      <div>
        共导入
        <span class="green">{{ shipdata.success + shipdata.err }}</span>
        条物流公司信息，成功
        <span class="green">{{ shipdata.success }}</span>
        条；导入失败
        <span class="green">{{ shipdata.err }}</span>
        条
      </div>
      <div class="text-center mar20">
        <el-button
          @click="errloaddse(shipdata.url)"
          v-if="shipdata.url != ''"
          type="primary"
          >下载导入失败模板</el-button
        >
        <el-button @click="dialogShiped = false" type="primary" v-else
          >知道了</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
import { leadLogisticsUrl } from "@/api/phpUrl.js";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      //导入发货弹窗
      dialogShiped: false,
      //导入发货数据
      shipdata: {
        count: 0, //总条数
        errNum: 0, //失败条数
        fileName: "",
      },
      UsersApi: {
        status: "", //
        keyWords: "", // 单位名称 法人 手机号关键字筛选
        page: 1,
      },
      usersData: {},
      AddData: {
        sort: 0,
      },
      islogistics: false,
      userType: [
        {
          name: "启用",
          type: 1,
        },
        {
          name: "禁用",
          type: 0,
        },
      ],
      zz: /^[0-9]*$/,
      zz1: /^[A-Za-z0-9]*$/,
      TOKEN: "",
      leadLogisticsUrl: "",
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.UsersApi = this.detailsSeach[router];
    }
    this.toSearch();
    this.TOKEN = getToken();
  },
  mounted() {
    this.leadLogisticsUrl = leadLogisticsUrl;
  },
  methods: {
    ...mapActions([
      "getlogisticslist",
      "postlogisticsadd",
      "postlogisticsedit",
      "postlogisticsdele",
      "getdownloadTemplate",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 删除
    async toDeltel(row) {
      this.$confirm(
        `确定要删除物流公司${row.name}？删除后商家发货时无法再选择当前物流公司且无法恢复，请谨慎操作。`,
        "提示",
        {
          title: "删除物流公司提醒",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.toDeltelapi(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除api
    async toDeltelapi(row) {
      let data = await this.postlogisticsdele({ id: row.id });
      if (data.code == 200) {
        this.toSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.toSearch();
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    /** 导入发货弹窗 */
    upSuccess(response, file) {
      if (response.code == 200) {
        this.dialogShiped = true;
        this.shipdata = response.data;
      } else {
        this.$message.error({ message: response.message });
      }
    },
    // 切换状态弹窗
    async checkType(row) {
      if (row.status_ == 0) {
        this.$confirm(
          `确定要启用物流公司${row.name}？启用后商家发货时将允许选择当前物流公司。`,
          "提示",
          {
            title: "启用物流公司提醒",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.checkTypeapi(row);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消启用",
            });
          });
      } else {
        this.$confirm(
          `确定要禁用物流公司${row.name}？禁用后商家发货时无法再选择当前物流公司。`,
          "提示",
          {
            title: "禁用物流公司提醒",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.checkTypeapi(row);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消禁用",
            });
          });
      }
      // let data = await this.postlogisticsedit(row);
      // if (data.code == 200) {
      //   this.toSearch();
      //   this.islogistics = false;
      //   this.$message({
      //     message: data.message,
      //     type: "success",
      //   });
      // } else {
      //   this.$message({
      //     message: data.message,
      //     type: "warning",
      //   });
      // }
    },
    // 切换状态接口
    async checkTypeapi(row) {
      row.status_ == 1 ? (row.status_ = 0) : (row.status_ = 1);
      let data = await this.postlogisticsedit(row);
      if (data.code == 200) {
        this.toSearch();
        this.islogistics = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 下载模板
    async toSearched() {
      let data = await this.getdownloadTemplate();
      if (data.code == 200) {
        window.open(data.data.url);
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    //下载失败模板
    errloaddse(url) {
      window.open(url);
      this.dialogShiped = false;
    },
    toSearch1() {
      this.page = 1;
      this.UsersApi.page = 1;
      this.toSearch();
    },
    // 新增编辑确认
    async afiyAdd() {
      if (!this.AddData.name || !this.AddData.code) {
        this.$message({
          message: "资料未填写完整",
          type: "warning",
        });
        return;
      }
      let data = {};
      let old = this.AddData.name;
      if (this.AddData.id) {
        data = await this.postlogisticsedit(this.AddData);
      } else {
        data = await this.postlogisticsadd(this.AddData);
      }
      if (data.code == 200) {
        // this.toSearch();
        this.islogistics = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    closeDialog() {
      this.toSearch();
    },
    // 打开新建弹框
    openAdd(row) {
      if (!row.id) {
        this.AddData = {};
        this.islogistics = true;
      } else {
        this.AddData = row;
        this.islogistics = true;
      }
    },
    handleCurrentChange(e) {
      this.UsersApi.page = e;
      this.toSearch();
    },
    async toSearch() {
      let data = await this.getlogisticslist(this.UsersApi);

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.UsersApi };
      save.router = router;
      this.changeSeach(save);

      this.usersData = data.data;
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 前往详情
    toMessage(row) {
      this.$router.push({
        path: "/usersMessage",
        query: { user_id: row.user_id, id: row.ID },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
//按键
.bulesd {
  margin-left: 10px;
  padding: 0px 10px 0px 10px;
  height: 40px;
  line-height: 40px;
  background: #06b7ae;
  color: #fff;
  font-size: 14px;

  .buled {
    color: #000;
    margin: 10px;
    font-size: 10px;
    line-height: 20px;
  }
}
</style>
